import $ from 'jquery';

if( $( '.js-sort' ).length ) {
  $( '.js-sort button' ).on( 'click', function ( e ) {
    e.preventDefault();
  
    var $this = $( this );
    var category = $this.data( 'category' );
  
    $this.siblings().removeClass( 'active' );
    $this.addClass( 'active' );
  
    //Foreach card we check if the catagory slug exists in the class attributes, hide it if it doesn't have the category class
    $( '.news-card' ).each( function() {
      var card = $( this );
  
      if ( card.hasClass( category ) || category == 'all' ) {
        card.each( function() {
          card.removeClass( 'hide-type' );
          card.removeClass( 'load-hide' );
          $( '.load-more.button' ).addClass( 'load-hide' );
        });
      } else {
        card.addClass( 'hide-type' );
      }
    });    
  }); 
  
  function myFunction( item, index ) {
    $( '.' + item ).removeClass( 'hide-cat' );
    $( '.' + item ).addClass( 'active-tag' );
    $( '.js-tag-active' ).addClass( 'active-tag' );
    $( '#clearFilter' ).addClass( 'active-tag' );
  }
  
  function filterEngine(){
    // Put all checked filter value in an array 
    var choices= [];
    $.each( $( "[name='news-filter']:checked" ), function(){
      choices.push( $( this ).val() ); 
    });
    
    // Filter list
    if( choices.length === 0 ){
      $( '.news-card' ).removeClass( 'hide-cat' );
      $( '.js-tag-btn' ).removeClass( 'active-tag' );
      $( '.js-tag-active' ).removeClass( 'active-tag' );
      $( '#clearFilter' ).removeClass( 'active-tag' );
    } else {
      $( '.news-card' ).addClass( 'hide-cat' );
      $( '.js-tag-btn' ).removeClass( 'active-tag' );
      choices.forEach( myFunction );
    } 
  };
  
  $( '.js-tag input' ).on( 'click', function ( ) {
    filterEngine();
  }); 
  
  //Clicking an active tag clears the tag, reruns filter and hash functions
  $( '.js-tag-active button' ).on( 'click', function () {
  
    var current = $( this ).val();
  
    $( '.js-tag' ).find( "[value='" + current + "']" ).prop( 'checked', false);
    $( this ).removeClass( 'active-tag' );
  
    filterEngine();
  });
  
  //Clears all active tags and closes dropdown
  $( '#clearFilter' ).on( 'click', function( e ) {
    e.preventDefault;
    filterEngine();
    $( '.news-card' ).removeClass( 'hide-cat' );
    $( this ).parents( '.container' ).find( "[name='news-filter']" ).prop( 'checked', false );
    $( '.js-tag-btn' ).removeClass( 'active-tag' ); 
    $( '.js-tag-active' ).removeClass( 'active-tag' );
    $( '#clearFilter' ).removeClass( 'active-tag' ); 
    $( '.dropdown' ).removeClass( 'open' );
    $( '.dropdown-info' ).removeClass( 'open' );
  });
  
  $( '.dropdown' ).on( 'click', function( e ) {
    e.preventDefault;
    $( '.dropdown' ).toggleClass( 'open' );
    $( '.dropdown-info' ).toggleClass( 'open' );
  })
}


if( $( '.load-more.button' ).length ) {
  $( '.load-more.button' ).on( 'click', function ( e ) {
    e.preventDefault();
    $( '.news-card' ).removeClass( 'load-hide' );
    $( '.load-more.button' ).addClass( 'load-hide' );
  });
}