import $ from 'jquery';

const filtersObject = {};
let filterDate = [];
let cardDates = {};
let cardInfo = [];
let cardIds = [];
let availableCards = $( '.cpt-card:not([data-availability = ""] )');

$( '.filter-title' ).on( 'click', function() {
  $( this ).toggleClass( 'active');
  $( this ).next().toggleClass( 'active');
})

$( '.rental-search-section .filter input' ).each( function () {
  let label = $( 'label[for=' + this.id + ']').html();
  $( '.active-tags' ).append('<div class="active-tag p hide" data-tag="'+ this.id +'">' + label + '</div>');
})

$( '.active-tag' ).on( 'click', function() {
  let id = $( this ).attr( 'data-tag' );
  $( this ).addClass( 'hide' );
  $( 'input:checkbox[id=' + id + ']' ).prop( "checked", false );
  $( '.filter input' ).trigger( 'change' );

})

$( '.filter input' ).on( 'change', function() {

  let filterName = $( this ).attr( 'name' );
  let filterVal = $( this ).val();
  let filterVals = [];

  if ( filterVal == '') {
    delete filtersObject[ filterName ];   
  } else if ( $( this ).attr( 'type' ) == 'date' ) {
    
    filterDate = new Date( $(this).val() );
    filterDate.setMinutes( filterDate.getMinutes() + filterDate.getTimezoneOffset())
    filtersObject[ filterName ] = filterDate;   

 

    $( availableCards ).each(function ( i ) {

      let id = $( this ).data('id'); 
      let unit = $( this ).data('unit-type'); 
      let date = new Date( $( this ).data('availability') );
      let diff = Math.abs( parseInt( ( filterDate - date ) / (1000 * 60 * 60 * 24), 10) ); 

      let unitInfo = [];

      unitInfo[ 'unit_type' ] = unit;
      unitInfo[ 'diff' ] = diff;
      unitInfo[ 'date' ] = date;
      unitInfo[ 'id' ] = id;
      cardInfo[ i ] = unitInfo;

    })
    cardDates = {};

    for ( let i = 0; i < cardInfo.length; i++) {
      let unittype = cardInfo[ i ].unit_type;

      if ( ! cardDates[ unittype ] ) {
        cardDates[ unittype ] = [];
      }
      cardDates[ unittype ].push( cardInfo[ i ] );
    }

    for (let unittype in cardDates) {
      let closest = cardDates[unittype][0]['diff'];
      let closestInfoId = cardDates[unittype][0]['id'];

        for( let j=1; j< cardDates[ unittype ].length; j++){

          if ( cardDates[ unittype ][ j ]['diff'] < closest ){
              closest = cardDates[ unittype ][ j ]['diff']; 
              closestInfoId = cardDates[ unittype ][ j ]['id'];
          }
        }
      cardDates[ unittype ] = closestInfoId;
    }    

    cardIds = [];
    $.each( cardDates, function( key, value ) {
      cardIds.push( value );
    });

  } else if ( $( this ).attr( 'type' ) == 'checkbox' ) {

    filterVals = $('input:checkbox[name=' + filterName + ']:checked').map(function() {
      return this.value;
    }).get();
    if( $( this ).is(":checked") ) {
      $( "[ data-tag='" + this.id + "' ]" ).removeClass('hide');
    } else {
      $( "[ data-tag='" + this.id + "' ]" ).addClass('hide');
    }

    if ( !filterVals || !filterVals.length ) {
      delete filtersObject[ filterName ];  
    } else {
      filtersObject[ filterName ] = filterVals;  
    }
  }
  if( $( '.active-tag').length === $( '.active-tag.hide').length ) {
    $( '.search-for' ).addClass( 'hide' );
   } else {
     $( '.search-for' ).removeClass( 'hide' );
   }
})

$( '.search-btn' ).on( 'click', function( e ) {
  e.preventDefault()
  
  //resets classes back to orignal before performing search again
  $( '.cpt-card' ).attr( 'class','cpt-card' );
  $( '.filter-title, .filter-dropdown').removeClass( 'active' );

    for ( let key in filtersObject ) {

      if ( filtersObject.hasOwnProperty( key ) ) {
        let filtersKey = [];
  
        if( typeof filtersObject[ key ] == 'object' ) {
          $.each( filtersObject[ key ], function( i, value) {
            filtersKey += "[ data-" + key + "='" + value + "' ]";
          });
          if( filtersKey.length !== 0 ) {
            filtersKey = filtersKey.split('][').join('],[');    
          }   
        } else {
          filtersKey += "[ data-" + key + "='" + filtersObject[ key ] + "' ]";
        }

        $( '.cpt-card' ).addClass( 'hide-' + key ); 

        if( key === 'availability' ) {
          $( '.cpt-card' ).filter(function () {

            let id = $( this ).data( 'id' )

            if( $.inArray( id, cardIds) === -1 ) {
              return false;
            } else {
              return true;
            }

          }).removeClass( 'hide-availability' );
        } else {
          $( '.cpt-card' ).filter( filtersKey ).removeClass( 'hide-' + key ); 
        }
      }
    }

    $( '.building-wrapper' ).each( function () {
      let cards = $( this ).find( '.cpt-card' );

      let results = $( cards ).filter( function() {
          return $( this ).css('display') == 'grid'
      }).length

      if( results === 0) {
        $( this ).addClass( 'hide' );
      } else {
        $( this ).removeClass( 'hide' );
      }
    })
})
